<template>
  <div
    id="app"
    @dragover.prevent="dragging"
    @dragend.prevent="dragleave"
    @drop.prevent
    @drop="drop($event)"
  >
    <Interface :dragged="dragged" :dragged_items="dragged_items"> </Interface>

  </div>
</template>

<script>
import Interface from './components/Interface.vue';

export default {
  name: 'App',
  components: {
    Interface
  },
  data() {
    return {
      updated: undefined,
      dragged: false,
      dragged_items: undefined
    };
  },
  methods: {
    /**
     * @TODO Docstrings
     */
    dragging() {
      this.dragged = true;
      this.updated = Date.now();
    },

    /**
     * @TODO Docstrings
     */
    drop(e) {
      this.dragged = false;
      var files = [];
      files.push(...e.dataTransfer.files);
      this.dragged_items = files;
    },

    /**
     * @TODO Docstrings
     */
    watchdog() {
      setTimeout(() => {
        // pause function for recursive watchdog
        if (this.updated !== undefined) {
          if (Date.now() - this.updated > 100) {
            // after 100 ms no mouse interaction -> close modal
            this.updated = undefined;
            this.dragged = false;
          }
        }
        this.watchdog();
      }, 1000);
    }
  },
  mounted() {
    this.watchdog();
  }
};
</script>

<style>
html {
  overflow: hidden !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
}



@font-face{
  font-family: "Roboto";
  src: url("fonts/Roboto/Roboto-X.woff2") format("woff2");
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #434343;
  width: 100%;
  height: 100%;
  overflow: hidden;
}



#app {
  font-family: Roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #434343;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.v-select:not(.v-text-field--single-line):not(.v-text-field--outlined) .v-select__slot > input {
  padding-left: 15px !important;
}
</style>
