var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer"},[_c('cookie-law',{attrs:{"theme":"dark-lime","buttonText":_vm.$ml.get('dsgvo_cookie_button')}},[_c('div',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.$ml.get('dsgvo_cookie_text'))},slot:"message"})]),(
      _vm.quota.used != '' &&
      _vm.quota.total != '' &&
      _vm.quota.free != '' &&
      _vm.quota.used_percent != '' &&
      _vm.status.files_loaded
    )?_c('table',[_c('tr',[_c('td',{staticClass:"usage"},[(!_vm.status.iswoloadmin)?_c('div',[_c('p',{domProps:{"innerHTML":_vm._s(
              _vm.$ml
                .get('quota_used')
                .replace('{0}', _vm.quota.used)
                .replace('{1}', _vm.quota.total)
                .replace('{2}', _vm.quota.used_percent)
            )}})]):_vm._e()]),_c('td',{staticClass:"imprint"},[_c('a',{attrs:{"href":"https://agj-freiburg.de/datenschutz","target":"_blank"}},[_c('p',{staticStyle:{"display":"inline"},domProps:{"innerHTML":_vm._s(_vm.$ml.get('privacy_policy'))}})]),_vm._v("   |   "),_c('a',{attrs:{"href":"https://agj-freiburg.de/impressum","target":"_blank"}},[_c('p',{staticStyle:{"display":"inline"},domProps:{"innerHTML":_vm._s(_vm.$ml.get('imprint'))}})])])])]):_vm._e(),(
      _vm.quota.used == '' &&
      _vm.quota.total == '' &&
      _vm.quota.free == '' &&
      _vm.quota.used_percent == '' &&
      !_vm.status.files_loaded
    )?_c('p',[_c('a',{attrs:{"href":"https://agj-freiburg.de/datenschutz"},domProps:{"innerHTML":_vm._s(_vm.$ml.get('privacy_policy'))}}),_vm._v("   |   "),_c('a',{attrs:{"href":"https://agj-freiburg.de/impressum"},domProps:{"innerHTML":_vm._s(_vm.$ml.get('imprint'))}})]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }