var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pw-reset"},[_c('v-dialog',{attrs:{"width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"logout-desktop"},'div',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account")]),_c('p',[_vm._v(_vm._s(_vm.truncate(_vm.status.username, 21)))])],1)]}}]),model:{value:(_vm.dialog_pwreset),callback:function ($$v) {_vm.dialog_pwreset=$$v},expression:"dialog_pwreset"}},[_c('v-card',[_c('v-card-title',[_c('v-icon',[_vm._v("mdi-home")]),_c('h4',{domProps:{"innerHTML":_vm._s(_vm.$ml.get('your_institution').replace('{0}', _vm.group))}})],1),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$ml.get('your_email'))}}),_c('p',[_vm._v(_vm._s(_vm.status.email))]),_c('v-card-text',[_c('v-divider'),_c('br'),_c('span',{staticClass:"text-h5",domProps:{"innerHTML":_vm._s(_vm.$ml.get('change_password'))}}),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.pw_change_form_valid),callback:function ($$v) {_vm.pw_change_form_valid=$$v},expression:"pw_change_form_valid"}},[_c('br'),_c('v-text-field',{staticClass:"pw_text-field",attrs:{"filled":"","label":_vm.$ml.get('current_password'),"append-icon":_vm.show_current_pw ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show_current_pw ? 'text' : 'password',"rules":[_vm.rules.empty],"required":"","name":"input-10-1"},on:{"click:append":function($event){_vm.show_current_pw = !_vm.show_current_pw},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.$emit('change_pw', _vm.pwresetquery_current, _vm.pwresetquery_new);
            _vm.dialog_pwreset = false;
            _vm.pwresetquery_current = '';
            _vm.pwresetquery_new = '';
            _vm.pwresetquery_repeat = '';
            _vm.pw_change_form_valid = true;}},model:{value:(_vm.pwresetquery_current),callback:function ($$v) {_vm.pwresetquery_current=$$v},expression:"pwresetquery_current"}}),_c('br'),_c('v-text-field',{staticClass:"pw_text-field",attrs:{"filled":"","label":_vm.$ml.get('new_password'),"append-icon":_vm.show_new_pw ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show_new_pw ? 'text' : 'password',"rules":[_vm.rules.empty, _vm.rules.min],"required":"","name":"input-10-1"},on:{"click:append":function($event){_vm.show_new_pw = !_vm.show_new_pw},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.$emit('change_pw', _vm.pwresetquery_current, _vm.pwresetquery_new);
            _vm.dialog_pwreset = false;
            _vm.pwresetquery_current = '';
            _vm.pwresetquery_new = '';
            _vm.pwresetquery_repeat = '';
            _vm.pw_change_form_valid = true;}},model:{value:(_vm.pwresetquery_new),callback:function ($$v) {_vm.pwresetquery_new=$$v},expression:"pwresetquery_new"}}),_c('br'),_c('v-text-field',{staticClass:"pw_text-field",attrs:{"filled":"","label":_vm.$ml.get('repeat_new_password'),"append-icon":_vm.show_repeat_pw ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show_repeat_pw ? 'text' : 'password',"rules":[_vm.rules.empty, _vm.rules.match],"required":"","name":"input-10-1"},on:{"click:append":function($event){_vm.show_repeat_pw = !_vm.show_repeat_pw},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.$emit('change_pw', _vm.pwresetquery_current, _vm.pwresetquery_new);
            _vm.dialog_pwreset = false;
            _vm.pwresetquery_current = '';
            _vm.pwresetquery_new = '';
            _vm.pwresetquery_repeat = '';
            _vm.pw_change_form_valid = true;}},model:{value:(_vm.pwresetquery_repeat),callback:function ($$v) {_vm.pwresetquery_repeat=$$v},expression:"pwresetquery_repeat"}})],1),_c('language-picker-profile',{staticStyle:{"padding-bottom":"20px"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{domProps:{"innerHTML":_vm._s(_vm.$ml.get('close'))},on:{"click":function($event){_vm.dialog_pwreset = false;
        _vm.pwresetquery_current = '';
        _vm.pwresetquery_new = '';
        _vm.pwresetquery_repeat = '';}}}),_c('v-btn',{staticStyle:{"color":"#fff","margin-left":"20px"},attrs:{"disabled":!_vm.pw_change_form_valid,"color":"#003F69"},domProps:{"innerHTML":_vm._s(_vm.$ml.get('change_password'))},on:{"click":function($event){_vm.$emit('change_pw', _vm.pwresetquery_current, _vm.pwresetquery_new);
            _vm.dialog_pwreset = false;
            _vm.pwresetquery_current = '';
            _vm.pwresetquery_new = '';
            _vm.pwresetquery_repeat = '';},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.$emit('change_pw', _vm.pwresetquery_current, _vm.pwresetquery_new);
            _vm.dialog_pwreset = false;
            _vm.pwresetquery_current = '';
            _vm.pwresetquery_new = '';
            _vm.pwresetquery_repeat = '';
            _vm.pw_change_form_valid = true;}}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }