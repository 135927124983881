var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.switchi == true ? '#009e73' : ''}},'v-icon',attrs,false),on),[_vm._v("mdi-lead-pencil")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$ml.get('permissions_change'))}})]),_c('v-divider'),_c('br'),_c('v-card-text',[_c('p',{staticStyle:{"text-align":"left"},domProps:{"innerHTML":_vm._s(
            _vm.share.item_type == 'folder'
              ? _vm.$ml.get('permissions_folder_text')
              : _vm.$ml.get('permissions_file_text')
          )}}),_c('v-checkbox',{attrs:{"label":_vm.share.item_type == 'folder'
              ? _vm.$ml.get('sharing_folder_permissions')
              : _vm.$ml.get('sharing_file_permissions')},on:{"change":function($event){return _vm.update()}},model:{value:(_vm.switchi),callback:function ($$v) {_vm.switchi=$$v},expression:"switchi"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},domProps:{"innerHTML":_vm._s(_vm.$ml.get('close'))},on:{"click":function($event){_vm.dialog = false}}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }