<template>
  <div>
    <v-skeleton-loader
      class="mx-auto"
      min-height="60"
      max-height="100"
      type="list-item-avatar"
      style="opacity: 1;"
    ></v-skeleton-loader>
    <v-skeleton-loader
      class="mx-auto"
      min-height="60"
      max-height="100"
      type="list-item-avatar"
      style="opacity: 0.8;"
    ></v-skeleton-loader>
    <v-skeleton-loader
      class="mx-auto"
      min-height="60"
      max-height="100"
      type="list-item-avatar"
      style="opacity: 0.6;"
    ></v-skeleton-loader>
    <v-skeleton-loader
      class="mx-auto"
      min-height="60"
      max-height="100"
      type="list-item-avatar"
      style="opacity: 0.4;"
    ></v-skeleton-loader>
    <v-skeleton-loader
      class="mx-auto"
      min-height="60"
      max-height="100"
      type="list-item-avatar"
      style="opacity: 0.3;"
    ></v-skeleton-loader>
    <v-skeleton-loader
      class="mx-auto"
      min-height="60"
      max-height="100"
      type="list-item-avatar"
      style="opacity: 0.2;"
    ></v-skeleton-loader>
    <v-skeleton-loader
      class="mx-auto"
      min-height="60"
      max-height="100"
      type="list-item-avatar"
      style="opacity: 0.1;"
    ></v-skeleton-loader>
  </div>
</template>

<script>
export default {
  name: 'Skeletons',
  props: {

  },
  data() {
    return {};
  },

  mounted() {
  },

  methods: {}
};
</script>