<!-- TODO RENAME THIS maYBE... its more then pw reset... Neuer Name: UserProfile-->
<template>
  <div class="pw-reset">
    <v-dialog v-model="dialog_pwreset" width="600px">
      <template v-slot:activator="{ on, attrs }">
        <div class="logout-desktop" v-bind="attrs" v-on="on">
          <v-icon>mdi-account</v-icon>
          <p>{{ truncate(status.username, 21) }}</p>
        </div>
      </template>
      <v-card>
        <v-card-title>
        <!-- ml: Your Institution: {0} -->
        <v-icon>mdi-home</v-icon><h4 v-html="$ml.get('your_institution').replace('{0}', group)"></h4>
        </v-card-title>
        

        <p v-html="$ml.get('your_email')"></p><p>{{status.email}}</p>
        <v-card-text>
          <v-divider></v-divider>
          <br>
          <span class="text-h5" v-html="$ml.get('change_password')"></span>
          <v-form ref="form" v-model="pw_change_form_valid" lazy-validation>
            <br>
            <v-text-field
              class="pw_text-field"
              filled
              v-model="pwresetquery_current"
              :label="$ml.get('current_password')"
              :append-icon="show_current_pw ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show_current_pw ? 'text' : 'password'"
              :rules="[rules.empty]"
              required
              name="input-10-1"
              @click:append="show_current_pw = !show_current_pw"
              @keyup.enter="
              $emit('change_pw', pwresetquery_current, pwresetquery_new);
              dialog_pwreset = false;
              pwresetquery_current = '';
              pwresetquery_new = '';
              pwresetquery_repeat = '';
              pw_change_form_valid = true;
            "
            ></v-text-field>
            <br>
            <v-text-field
              class="pw_text-field"
              filled
              v-model="pwresetquery_new"
              :label="$ml.get('new_password')"
              :append-icon="show_new_pw ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show_new_pw ? 'text' : 'password'"
              :rules="[rules.empty, rules.min]"
              required
              name="input-10-1"
              @click:append="show_new_pw = !show_new_pw"
              @keyup.enter="
              $emit('change_pw', pwresetquery_current, pwresetquery_new);
              dialog_pwreset = false;
              pwresetquery_current = '';
              pwresetquery_new = '';
              pwresetquery_repeat = '';
              pw_change_form_valid = true;
            "
            ></v-text-field>
            <br>
            <v-text-field
              class="pw_text-field"
              filled
              v-model="pwresetquery_repeat"
              :label="$ml.get('repeat_new_password')"
              :append-icon="show_repeat_pw ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show_repeat_pw ? 'text' : 'password'"
              :rules="[rules.empty, rules.match]"
              required
              name="input-10-1"
              @click:append="show_repeat_pw = !show_repeat_pw"
              @keyup.enter="
              $emit('change_pw', pwresetquery_current, pwresetquery_new);
              dialog_pwreset = false;
              pwresetquery_current = '';
              pwresetquery_new = '';
              pwresetquery_repeat = '';
              pw_change_form_valid = true;
            "
            ></v-text-field>
          </v-form>
          <language-picker-profile style="padding-bottom: 20px"></language-picker-profile>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="
          dialog_pwreset = false;
          pwresetquery_current = '';
          pwresetquery_new = '';
          pwresetquery_repeat = '';
          " v-html="$ml.get('close')"></v-btn>
          <v-btn
            :disabled="!pw_change_form_valid"
            color="#003F69"
            style="color: #fff; margin-left: 20px"
            @click="
              $emit('change_pw', pwresetquery_current, pwresetquery_new);
              dialog_pwreset = false;
              pwresetquery_current = '';
              pwresetquery_new = '';
              pwresetquery_repeat = '';
            "
            @keyup.enter="
              $emit('change_pw', pwresetquery_current, pwresetquery_new);
              dialog_pwreset = false;
              pwresetquery_current = '';
              pwresetquery_new = '';
              pwresetquery_repeat = '';
              pw_change_form_valid = true;
            "
            v-html="$ml.get('change_password')"
          ></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LanguagePickerProfile from './LanguagePickerProfile.vue';

export default {
  components: { LanguagePickerProfile },
  name: 'PasswordReset',
  props: {
    status: Object,
    group: String,
  },
  data() {
    return {
      dialog_pwreset: false,
      show_current_pw: false,
      show_new_pw: false,
      show_repeat_pw: false,
      pwresetquery_current: '',
      pwresetquery_new: '',
      pwresetquery_repeat: '',
      rules: {
        empty: v => !!v || this.$ml.get('pw_rules_empty'),
        min: v => (v && v.length >= 10) || this.$ml.get('pw_rules_min'),
        match: v => this.pwresetquery_new === v || this.$ml.get('pw_rules_match'),
      },
      pw_change_form_valid: true
    };
  },

  mounted() {},

  methods: {
    truncate(str, n) {
      return str.length > n ? str.slice(0, n - 1) + '...' : str;
    },
  }
};
</script>
<style scoped>
  .pw_text-field.error--text,
  .pw_text-field .error--text{
    color: red !important;
    background: rgba(255, 17, 17, 0.26) !important;
  }
</style>