<template>
  <v-form v-if="!loading" ref="form" v-model="pw_change_form_valid" lazy-validation>
    <!-- ml: Name -->
    <v-text-field
      :disabled="true"
      :key="componentKey"
      v-model="institution_member.id"
      :label="$ml.get('name_short')"
    ></v-text-field>
    <!-- ml: E-mail address -->
    <v-text-field
      :disabled="true"
      v-model="institution_member.email"
      :label="$ml.get('email_placeholder')"
    ></v-text-field>
    <!-- ml: Password -->
    <v-text-field
      :disabled="!edit"
      v-model="pwresetquery_new"
      :rules="[rules.empty, rules.min]"
      type='password'
      required
      :label="$ml.get('new_password')"

    >
    </v-text-field>
    <v-text-field
      :disabled="!edit"
      v-model="pwresetquery_repeat"
      :rules="[rules.empty, rules.match]"
      type='password'
      required
      :label="$ml.get('repeat_new_password')"
    >
    </v-text-field>

    <!-- ml: Edit account -->
    <v-btn v-if="!edit" @click="enableedit(); pwresetquery_new=''; pwresetquery_repeat=''" v-html="$ml.get('edit_account')"
      >&nbsp; <v-icon>mdi-pencil</v-icon></v-btn
    >
    <v-btn v-if="edit" @click="disableedit(); pwresetquery_new='*****************'; pwresetquery_repeat='*****************';" v-html="$ml.get('cancel')"></v-btn>&nbsp;&nbsp;
    <v-btn
      v-if="edit"
      @click="save(institution_member)"
      style="color: #fff"
      color="#006AB3"
      v-html="$ml.get('save')"
      :disabled="!pw_change_form_valid || pwresetquery_new === '*****************'"
    ></v-btn>
    <br /><br />
    <!-- ml: Last login -->

    <!--
    <p v-html="$ml.get('last_login')">


      &nbsp;
      {{
        institution_member.lastLogin == 0
          ? $ml.get('never_logged_in')
          : institution_member.lastLogin
      }}
    </p>

    -->
    <br />
    <!-- ml: Storage: {0}% of {1} used -->

    <!-- 
    <p
      v-html="
        $ml
          .get('quota_used_social')
          .replace('{0}', institution_member.quota_percent)
          .replace('{1}', institution_member.quota_max_string)
      "
    ></p>

    -->
    <br />

    
    <!-- ml: Delete account -->

    <!--
    <v-btn @click="delete_member(institution_member, institution)"
      ><p v-html="$ml.get('delete_account')"></p></v-btn
    ><br /><br />
 -->



    <!-- ml: Resend the welcome e-mail -->
    <v-btn @click="resend_welcome(institution_member.id)"
      ><p v-html="$ml.get('resend_welcome_email')"></p
    ></v-btn>

    <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
  </v-form>
</template>

<script>

export default {
  name: 'MemberControl',
  props: {
    institution: String,
    institution_member: Object,
    loading: Boolean,
    shown: Boolean,
  },
  data() {
    return {
      edit: false,
      componentKey: 0,
      pwresetquery_new: '*****************',
      pwresetquery_repeat: '*****************',
      rules: {
        empty: v => !!v || this.$ml.get('pw_rules_empty'),
        min: v => (v && v.length >= 10) || this.$ml.get('pw_rules_min'),
        match: v => this.pwresetquery_new === v || this.$ml.get('pw_rules_match'),
      },
      pw_change_form_valid: true
    };
  },

  mounted() {
    this.pwresetquery_new= '*****************';
    this.pwresetquery_repeat= '*****************';
    this.edit = false;

  },
  watch: {
    shown(v) {

      if(v==false) {
        this.edit = false;
        this.pwresetquery_new= '*****************';
        this.pwresetquery_repeat= '*****************';




        
      }
    },
    loading(v) {
      if (!v) {
        this.forceRerender();
      }
    }
  },
  methods: {

    resend_welcome(id) {
      this.$emit('resend_welcome', id);
    },
    save(institution_member) {
      this.update_member(
        institution_member.id,
        institution_member.email,
        institution_member.id,
        this.pwresetquery_repeat
      );

      this.disableedit();
    },
    enableedit() {
      this.edit = true;
      this.forceRerender();
    },
    disableedit() {
      this.edit = false;
      this.forceRerender();
    },
    update_member(memberid, email, displayname, password) {
      if (memberid !== undefined) {
        let updates = {};
        // email !== undefined ? (updates.email = email) : () => {};
        // displayname !== undefined ? (updates.id = displayname) : () => {};
        password !== undefined ? (updates.password = password) : () => {};
        this.$emit('admin_update_member', memberid, updates, this.institution);
      }
    },

    forceRerender() {
      this.componentKey += 1;
    },

    delete_member(institution_member, insitution) {
      this.$emit('admin_delete', institution_member.id, insitution);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
