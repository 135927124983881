<template>
  <div class="dummies-wrapper" style="width: 100%" >
    <div v-for="ufile in uploading_files" :key="ufile.name + ''">
      <v-card class="listelement">
        <v-icon class="iconinlist-file">mdi-file-outline</v-icon>
        <v-progress-circular
        class="progress-bar"
        indeterminate 
        color="#99b533"
        >
        </v-progress-circular>
        <p class="listelement-text">{{ ufile.name }} &nbsp;</p>
        <p class="listelement-text-mobile">{{ ufile.name }}</p>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UploadDummies',
  props: {
    uploading_files: Array,
    status: Object
  },
  data() {
    return {};
  },

  mounted() {
  },

  methods: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.iconinlist-file {
  margin-right: 20px;
  background: #006ab3;
  height: 60px;
  width: 60px;
  float: left;
  color: #fff !important;
}

.progress-bar {
  height: 60px;
  width: 60px;
  float: right;
  margin: 1rem;
}

.listelement {
  height: 60px !important;
  width: calc(100% - 25px) !important;
  background-color: rgba(253, 246, 227, 1) !important;
  margin: 12px !important;
}

.listelement p {
  padding-top: 15px;
  width: 100%;
  text-align: left;
}

.listelement-text {
  width: 80% !important;
  overflow: hidden;
  line-height: 1.9;
  height: 45px;
  font-size: 18px;
  display: flex;
  text-align: left;
}

.listelement-text-mobile {
  display: none;
}

.dummies-wrapper {
  padding: 2% 3% 2.5% 2%;
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
  scrollbar-width: thin;
}

.platzhalter {
  color: black;
}
</style>