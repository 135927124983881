var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shares-list-item-icons"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}}),_c('div',{staticClass:"shares-list-item-permissions"},[_c('v-tooltip',{staticClass:"modal-icon menu-icon shares-list-item-permissions-icon",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('date-modal',_vm._g(_vm._b({attrs:{"share_date":_vm.share.expiration},on:{"update_share":(key, value) => {
              _vm.update_share(key, value);
            }}},'date-modal',attrs,false),on))]}}])},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$ml.get('shared_until').replace('{0}', _vm.share.expiration))}})]),(_vm.share.share_type != 1)?_c('v-tooltip',{staticClass:"modal-icon menu-icon shares-list-item-permissions-icon",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('password-modal',_vm._g(_vm._b({attrs:{"password":_vm.share.password},on:{"update_share":(key, value) => {
              _vm.update_share(key, value);
            }}},'password-modal',attrs,false),on))]}}],null,false,4027957389)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$ml.get('document_password_protected'))}})]):_vm._e(),_c('v-tooltip',{staticClass:"modal-icon menu-icon shares-list-item-permissions-icon",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('permissions-modal',_vm._g(_vm._b({attrs:{"editable":_vm.share.permissions > 1,"share":_vm.share},on:{"update_share":(key, value) => {
              _vm.update_share(key, value);
            }}},'permissions-modal',attrs,false),on))]}}])},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$ml.get('shared_until_short').replace('{0}', _vm.share.expiration))}})])],1),_c('v-tooltip',{staticClass:"modal-icon menu-icon shares-list-item-icon-remove",attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"modal-icon menu-icon shares-list-item-icon-remove-icon",attrs:{"size":"30px","color":"#d55e00"},on:{"click":function($event){return _vm.delete_share()}}},'v-icon',attrs,false),on),[_vm._v("mdi-minus-circle")])]}}])},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$ml.get('remove'))}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }