<template>
  <v-snackbar v-model="dialog_intern" :timeout="timeout" class="snackbar" :color="color">
    <p>{{ this.message }}</p>
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="dialog_intern = false"><p v-html="$ml.get('close')"></p></v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'Toast',
  props: {
    dialog: Boolean,
    timeout: Number,
    message: String,
    color: String,
  },
  components: {},
  data() {
    return {
        dialog_intern:undefined,
    };
  },
  watch: {
    dialog(v) {
        this.dialog_intern = v;
    },
    message() {
        this.dialog_intern = this.dialog;
    }
  }

};
</script>

<style>
.snackbar {
  margin-bottom: 50px;
}
</style>
