<template>
  <div>
    <img id="header-logo" alt="Cloud fuer wohnungslose Menschen" src="./../assets/cloud-logo.png" />

    <div class="login">
      <v-icon class="fancy-icon" x-large>mdi-lock</v-icon>
      <!-- ml: ACCESS TO THE CLOUD FOR HOMELESS PEOPLE -->
      <h2 class="header-title" v-html="$ml.get('header_title')"></h2>
      <!-- ml: Login name -->
      <!-- ml: firstname.lastname.birthyear | example: John.Doe.1990 -->
      <v-text-field
        filled
        :label="$ml.get('username_placeholder')"
        :hint="$ml.get('username_hint')"
        v-model="username_login"
        @input="update_username"
        :disabled="this.status.loading"
      ></v-text-field>

      <!-- ml: Password -->
      <!-- ml: Remember to never tell your password to anyone - even we will never ask you for it! -->
      <v-text-field
        filled
        v-model="password_login"
        :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showpassword ? 'text' : 'password'"
        name="input-10-1"
        :disabled="this.status.loading"
        :label="$ml.get('password_placeholder')"
        @input="update_password"
        :hint="$ml.get('password_hint')"
        @click:append="showpassword = !showpassword"
        @keyup.enter="login()"
      ></v-text-field>

      <!-- Anmelden -->
      <v-btn
        style="color: #fff"
        class="login-button"
        rounded
        x-large
        :disabled="this.status.loading"
        color="#009e73"
        @click="login()"
        @keyup.enter="login()"
        v-html="$ml.get('login_button')"
      ></v-btn>
    </div>

    <!--loading overlay -->
    <v-overlay :absolute="false" :value="status.loading">
      <div v-if="this.status.loading || (!this.status.logged_in && this.status.loading)">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: 'Login',
  props: {
    loggedIn: Boolean,
    status: Object
  },
  data() {
    return {
      dialog: false,
      showpassword: false,
      password_login: '',
      username_login: ''
    };
  },


  methods: {
    // login attemp, will be handled in interface
    login() {
      this.$emit('login');

      // no use for clear text pw storage, rest will be handled via tokens
      this.password_login = '';
      this.username_login = '';
    },

    update_password() {
      this.$emit('update_password', this.password_login);
    },

    update_username() {
      this.$emit('update_username', this.username_login);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login {
  background: #fff;
  width: 30%;
  margin-left: 35%;
  height: 65vh;
  padding: 3%;
  border-radius: 50px;
}

.fancy-icon {
  font-size: 50px !important;
  color: #009e73 !important;
  padding-bottom: 2% !important;
}

#header-logo {
  padding: 3% 5% 3% 5%;
  width: 40%;
}

.header-title {
  color: #009e73;
  margin-bottom: 10%;
  font-size: 1.3em;
}
.login-button {
  margin-top: 10%;
  font-size: 20px;
}

.pw-vergessen {
  margin-top: 2%;
  width: 100%;
}

@media only screen and (max-width: 700px) {
  #header-logo {
    padding:0;
    width: 50%;
    margin: 15% 25% 5% 25%;
  }

  .login {
    background: #fff;
    width: 90%;
    margin-left: 5%;
    height: 450px;
    padding: 5%;
    border-radius: 50px;
  }
  .header-title {
    color: #009e73;
    margin-bottom: 10%;
    font-size: 1.3em;
  }
}
</style>
