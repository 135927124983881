var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"margin-right":"7px"},attrs:{"color":_vm.password!=undefined && _vm.password!='' ? '#99b533' : ''}},'v-icon',attrs,false),on),[_vm._v("mdi-key")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('h4',{domProps:{"innerHTML":_vm._s(_vm.$ml.get('sharing_protect_pw_cb'))}})]),_c('br'),_c('v-divider'),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.sharing_pw_form_valid),callback:function ($$v) {_vm.sharing_pw_form_valid=$$v},expression:"sharing_pw_form_valid"}},[_c('v-text-field',{attrs:{"background-color":"#f1f3f4","height":"50","label":_vm.$ml.get('password_placeholder'),"rules":[_vm.rules.min],"required":"","type":_vm.show_sharing_pw ? 'text' : 'password'},on:{"click:append":function($event){_vm.show_sharing_pw = !_vm.show_sharing_pw}},model:{value:(_vm.pw),callback:function ($$v) {_vm.pw=$$v},expression:"pw"}}),_c('v-btn',{attrs:{"disabled":!_vm.sharing_pw_form_valid},on:{"click":() => {
                _vm.update();
                _vm.dialog = false;
              },"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return (() => {
                _vm.update();
                _vm.dialog = false;
              }).apply(null, arguments)}}},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$ml.get('save'))}})])],1)],1),_c('v-divider'),_c('v-btn',{attrs:{"disabled":!_vm.sharing_pw_form_valid},on:{"click":() => {
                this.pw = '';
                _vm.update();
                _vm.dialog = false;
              }}},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$ml.get('remove_password'))}})]),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},domProps:{"innerHTML":_vm._s(_vm.$ml.get('close'))},on:{"click":function($event){_vm.dialog = false}}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }