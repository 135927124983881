<template>
  <div class="ml-menu-profile">
    <v-select
      class="ml-profile"
      v-model="lang"
      :items="$ml.list"
      menu-props="auto"
      label="Sprache / Language"
      hide-details
      prepend-icon="mdi-translate"
      single-line
    ></v-select>
  </div>
</template>

<script>
export default {
  name: 'LanguagePickerProfile',
  props: {
  },
  components: {},
  data() {
    return {
      lang: undefined
    };
  },
  methods: {},
  mounted() {
    this.lang = this.$ml.current;
  },
  watch: {
    lang() {
      this.$ml.change(this.lang);
    }
  }
};
</script>

<style>

</style>
