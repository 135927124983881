<template>
  <div>
    <v-dialog v-model="dialog" width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-icon style="margin-right: 7px" v-bind="attrs" v-on="on" 
          :color="date != undefined && date != '' ? '#009e73' : ''">mdi-clock</v-icon>
      </template>

      <v-card>
        <v-card-title>
          <span v-html="$ml.get('sharing_protect_limited_date_text')"></span>
        </v-card-title>

        <v-divider></v-divider>
        <br />
        <p
          v-if="this.date !== ''"
          v-html="$ml.get('sharing_protect_limited_date_info').replace('{0}', this.date)"
        ></p>
        <p v-else v-html="$ml.get('sharing_protect_limited_date_info_undef')"></p>
        <br />
        <v-card-text>
          <v-date-picker
            dark
            v-model="date"
            full-width
            class="mt-4"
            @change="update()"
            :min="get_date_tomorrow().toISOString().substr(0, 10)"
          ></v-date-picker>

          <v-btn
            @click="
              date = '';
              update();
            "
            ><p v-html="$ml.get('sharing_protect_limited_date_remove')"></p
          ></v-btn>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text @click="dialog = false" v-html="$ml.get('close')"></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'dateModal',
  props: {
    share_date: String
  },
  data() {
    return {
      dialog: false,
      date: ''
    };
  },

  mounted() {
    this.date = this.share_date;
  },

  methods: {
    get_date_tomorrow() {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      return tomorrow;
    },
    update() {
      this.$emit('update_share', 'expireDate', this.date);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
