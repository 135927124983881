import Vue from 'vue'
import { MLInstaller, MLCreate, MLanguage } from 'vue-multilanguage'

Vue.use(MLInstaller)

export default new MLCreate({
  initial: 'Deutsch',
  save: process.env.NODE_ENV === 'production',
  languages: [
    new MLanguage('Deutsch').create({
      // buttons
      new_folder: "Ordner erstellen",
      login_button: "Anmelden",
      logout_button: "Abmelden",
      cancel: "Abbrechen",
      close: "Schließen",
      create: "Erstellen",
      save: "Speichern",
      share: "Freigeben",
      share_long: "An Einrichtung freigeben",
      remove: "Entfernen",
      delete: "Löschen",
      download: "Herunterladen",
      upload: "Hochladen",
      mobile_scan: "Scan",
      dsgvo_cookie_button: "Verstanden",
      dsgvo_cookie_text: "Diese Website verwendet Cookies, um einen reibungslosen Ablauf der Cloud zu garantieren.",
      remove_password: "Passwort entfernen",

      // text
      noClientForSozi: "Keine Klienten verfügbar",
      header_title: "ZUGANG ZUR CLOUD FÜR WOHNUNGSLOSE MENSCHEN",
      username_placeholder: "Anmeldename",
      username_hint: "Anmeldename (VornameNachname)",
      password_placeholder: "Passwort",
      password_hint: "Denk daran, Niemandem jemals Dein Passwort zu verraten - auch wir werden Dich nie danach fragen!",
      password_reset_button: "Profil",
      password_reset_btn_text: "Ändere dein Passwort hier:",
      current_password: "Aktuelles Passwort",
      new_password: "Neues Passwort",
      repeat_password: "Passwort wiederholen",
      repeat_new_password: "Neues Passwort wiederholen",
      change_password: "Passwort ändern",
      pw_changed: "Passwort wurde geändert",
      pw_rules_empty: "Das Feld darf nicht leer sein",
      pw_rules_min: "Passwort muss mindestens 10 Zeichen haben",
      pw_rules_match: "Die Passwörter müssen übereinstimmen",
      pw_rules_compromised: "Das Passwort wurde unter den am häufigsten genutzen Passwörtern gefunden",
      dir_rules_maxlength: "Der Name ist zu lang. Bitte versuche einen kürzeren.",
      name: "Anmeldename (VornameNachname)",
      name_short: "Anmeldename (VornameNachname)",
      email_rule: "Bitte gültige E-Mail-Adresse eingeben",
      pw_create: "Passwort direkt festlegen",
      create_account: "Account erstellen",
      edit_account: "Account bearbeiten",
      delete_account: "Account löschen",
      remove_from_institution: "Aus Einrichtung entfernen",
      resend_welcome_email: "Willkommens-E-Mail erneut senden",
      welcome_email_sent: "Willkommens-E-Mail gesendet",
      last_login: "Zuletzt eingeloggt",
      never_logged_in: "Noch nie eingeloggt",
      your_clients: "Deine Klient*innen",
      your_files: "Deine Dokumente",
      moving_file: "{0} wird verschoben",
      file_move_here: "Hierhin verschieben",
      file_moved: "Datei wurde verschoben",
      move_error: "Fehler beim Verschieben",
      preview: "Vorschau",
      no_preview_available: "Keine Vorschau verfügbar",
      your_institution: "Deine Einrichtung: {0}",
      drop_documents: "Lass' die Maustaste hier los, um Dokument(e) hochzuladen.",
      your_documents: "Deine Dokumente",
      new_folder_text: "Wie soll der neue Ordner heißen?",
      privacy_policy: "Datenschutz",
      imprint: "Impressum",
      manage_wolo_text1: "Klient*in von {0}",
      create_member_help: "Wenn Du kein Passwort gemeinsam mit der/dem Klient*in festlegst, bekommt sie/er eine E-Mail zum eigenständigen Erstellen eines Passworts zugeschickt.",
      member_created_success: "Klient*in {0} wurde erfolgreich erstellt",
      shared_docs_text: "Ordner <b>„{0}“</b> freigeben",
      share_search_placeholder: "Name der Person oder Einrichtung",
      share_institutions_with_access: "Link- und Einrichtungsfreigaben",
      email_placeholder: "E-Mail-Adresse",
      shared_until: "Freigabe ist zeitlich begrenzt bis {0}",
      shared_until_short: "Freigegeben bis {0}",
      documents_upload: "Dokument(e) hochladen",
      choose_files: "Dokument(e) auswählen",
      upload_placeholder: "Wähle Dein(e) Dokument(e) aus",
      copy_link: "Link kopieren",
      sharing_create_link: "Freigabe Link erstellen",
      sharing_create_email: "Freigabe-E-Mail erstellen",
      sharing_give_access: "Zugriff freigeben",
      sharing_protect_pw_cb: "Mit Passwort schützen",
      sharing_protect_limited_date_info: "Freigabe gilt bis: {0}",
      sharing_protect_limited_date_info_undef: "Die Freigabe ist unbegrenzt",
      sharing_protect_limited_date_text: "Bis wann soll die Freigabe gelten?",
      sharing_protect_limited_date_remove: "Datum entfernen",
      movesimple: "Verschieben",
      renamesimple: "Umbenennen",
      rename: "{0} umbenennen",
      rename_text: "Wie soll der neue Name lauten?",
      delete_check: "Möchtest Du <b>{0}</b> wirklich löschen?",
      file: "Datei",
      files: "Dateien",
      quota_used: "Du hast {0} von {1} Datenvolumen verbraucht. ({2}%)",
      quota_used_social: "Speicher: {0}% von {1} verbraucht.",
      document_password_protected: "Dokument ist passwortgeschützt",
      permissions_change: "Berechtigungen ändern",
      permissions_file_text: "Darf die Einrichtung / Sozialarbeiter*in dieses Dokument bearbeiten?",
      permissions_folder_text: "Darf die Einrichtung / Sozialarbeiter*in Dokumente bearbeiten und hochladen?",
      sharing_file_permissions: "Darf dieses Dokument bearbeiten",
      sharing_folder_permissions: "Darf Dokumente hochladen/überschreiben und bearbeiten",
      no_files_here_client: "In diesem Ordner befinden sich keine Daten.",
      search: "Suche im aktuellen Ordner",
      no_files_here_client_search: "Die Suche ergab keine Ergebnisse",
      save_success: "Erfolgreich gespeichert",
      no_email: "Keine E-Mail-Adresse angegeben",
      your_email: "Deine E-Mail-Adresse:",

      // admin
      create_wolo: "Neue*n Klient*in anlegen",
      no_files_got_shared: "Dir wurde nichts geteilt.",
      no_files_here_admin: "In diesem Ordner wurde Dir nichts geteilt.",

      // alerts / notifications
      notify_copy_link: "Link kopiert!",
      rename_success: "Erfolgreich umbenannt ",
      rename_error: "Fehler beim Umbennen ",
      function_unavailable: "Diese Funktion ist noch nicht verfügbar",
      end_sharing_success: "Freigabe unterbrochen ",
      end_sharing_error: "Fehler beim beenden der Freigabe ",
      delete_success: "Löschen erfolgreich ",
      delete_error: "Fehler beim Löschen ",
      unlimited_quota: "unendlich",
      quota_load_success: "Quota Erfolgreich geladen ",
      quota_load_error: "Fehler beim Laden der Quota ",
      new_share_success: "Das hat geklappt",
      shared_load_success: "Freigegebene Dokumente erfolgreich geladen ",
      shared_load_error: "Fehler beim Laden der freigegebenen Dokumente ",
      upload_success: "Upload erfolgreich",
      upload_error: "Upload ist hier nicht erlaubt",
      download_folder_error: "Dieser Ordner kann gerade leider nicht heruntergeladen werden",
      download_finished: "Download abgeschlossen",
      download_error: "Der Download war leider nicht erfolgreich",
      new_folder_created_success: "Neuer Ordner {0} wurde erstellt",
      new_folder_created_error: "Der Ordner konnte nicht erstellt werden",
      load_contents_successful: "Inhalt erfolgreich geladen",
      folder_unavailable: "Ordner nicht verfügbar",
      login_error: "Login nicht erfolgreich. Überprüfe bitte Deinen Anmeldenamen und das Passwort.",
      empty_upload: "Keine Dokumente ausgewählt.",
      email_forbidden: "Ein Login via Email ist nicht erlaubt, bitte nutze Deinen Anmeldename",
      logout_countdown: "Auto-Abmeldung",
      soon_logout: "Du wirst in {0} Sekunden automatisch abgemeldet!",
      pls_reset_time: "Angemeldet bleiben",
      
      // containers / file stuff
      filesizes: ["Bytes", "KB", "MB", "GB", "TB"],

    }),

    new MLanguage('English').create({
      // buttons
      new_folder: "Create folder",
      login_button: "Log in",
      logout_button: "Log out",
      cancel: "Cancel",
      close: "Close",
      create: "Create",
      save: "Save",
      share: "Share",
      share_long: "Share with institution",
      remove: "Remove",
      delete: "Delete",
      download: "Download",
      upload: "Upload",
      mobile_scan: "Scan",
      dsgvo_cookie_button: "Understood",
      dsgvo_cookie_text: "This website uses cookies to ensure a smooth cloud experience",
      remove_password: "Remove password",

      // text
      noClientForSozi: "No clients available",
      header_title: "ACCESS TO THE CLOUD FOR HOMELESS PEOPLE",
      username_placeholder: "Login name",
      username_hint: "Login name (FirstnameLastname)",
      password_placeholder: "Password",
      password_hint: "Remember never to tell anyone your password - we will never ask you for it either!",
      password_reset_button: "Profile",
      password_reset_btn_text: "Change your password here:",
      current_password: "Current password",
      new_password: "New password",
      repeat_password: "Repeat password",
      repeat_new_password: "Repeat new password",
      change_password: "Change password",
      pw_changed: "Password has been changed",
      pw_rules_empty: "The field must not be empty",
      pw_rules_min: "Password must have at least 10 characters",
      pw_rules_match: "The passwords must match",
      pw_rules_compromised: "The password was found among the most frequently used passwords",
      dir_rules_maxlength: "The name is too long. Please try a shorter one.",
      name: "Login name (FirstnameLastname)",
      name_short: "Login Name (FirstnameLastname)",
      email_rule: "Please enter a valid email address",
      pw_create: "Set password directly",
      create_account: "Create account",
      edit_account: "Edit account",
      delete_account: "Delete account",
      remove_from_institution: "Remove from institution",
      resend_welcome_email: "Resend welcome email",
      welcome_email_sent: "Welcome email sent",
      last_login: "Last logged in",
      never_logged_in: "Never logged in before",
      your_clients: "Your clients",
      your_files: "Your documents",
      moving_file: "{0} is being moved",
      file_move_here: "Move here",
      file_moved: "File has been moved",
      move_error: "Error while moving",
      preview: "Preview",
      no_preview_available: "No preview available",
      your_institution: "Your institution: {0}",
      drop_documents: "Release the mouse button here to upload document(s)",
      your_documents: "Your documents",
      new_folder_text: "What should the new folder be called?",
      privacy_policy: "Data protection",
      imprint: "Imprint",
      manage_wolo_text1: "Client of {0}",
      create_member_help: "If you do not set a password together with the client, he/she will receive an email to create a password on his/her own.",
      member_created_success: "Client {0} has been successfully created",
      shared_docs_text: "Share <b>'{0}'</b> folder",
      share_search_placeholder: "Name of person or institution",
      share_institutions_with_access: "Link and institution shares",
      email_placeholder: "Email address",
      shared_until: "Share is time limited until {0}",
      shared_until_short: "Shared until {0}",
      documents_upload: "Upload document(s)",
      choose_files: "Select document(s)",
      upload_placeholder: "Select your document(s)",
      copy_link: "copy link",
      sharing_create_link: "Create sharing link",
      sharing_create_email: "create share email",
      sharing_give_access: "Share access",
      sharing_protect_pw_cb: "Protect with password",
      sharing_protect_limited_date_info: "Sharing valid until: {0}",
      sharing_protect_limited_date_info_undef: "Sharing is unlimited",
      sharing_protect_limited_date_text: "Until when should the sharing be valid?",
      sharing_protect_limited_date_remove: "Remove date",
      movesimple: "Move",
      renamesimple: "Rename",
      rename: "{0} rename",
      rename_text: "What should the new name be?",
      delete_check: "Do you really want to delete <b>{0}</b>?",
      file: "File",
      files: "Files",
      quota_used: "You have used {0} of {1} data volume. ({2}%)",
      quota_used_social: "Memory: {0}% of {1} used.",
      document_password_protected: "Document is password protected",
      permissions_change: "Change permissions",
      permissions_file_text: "Is the institution/social worker allowed to edit this document?",
      permissions_folder_text: "Is the institution / social worker allowed to edit and upload documents?",
      sharing_file_permissions: "May edit this document",
      sharing_folder_permissions: "May upload/overwrite and edit documents",
      no_files_here_client: "There is no data in this folder",
      search: "Search in the current folder",
      no_files_here_client_search: "The search returned no results",
      save_success: "Successfully saved",
      no_email: "No email address specified",
      your_email: "Your email address:",

      // admin
      create_wolo: "Create a new client",
      no_files_got_shared: "Nothing has been shared with you",
      no_files_here_admin: "Nothing has been shared with you in this folder",

      // alerts / notifications
      notify_copy_link: "Link copied!",
      rename_success: "Successfully renamed ",
      rename_error: "Error renaming ",
      function_unavailable: "This function is not yet available",
      end_sharing_success: "Sharing interrupted ",
      end_sharing_error: "Error terminating sharing ",
      delete_success: "Delete successful ",
      delete_error: "Error deleting ",
      unlimited_quota: "infinite",
      quota_load_success: "Quota loaded successfully ",
      quota_load_error: "Error loading quota ",
      new_share_success: "This worked",
      shared_load_success: "Shared documents loaded successfully ",
      shared_load_error: "Error loading shared documents ",
      upload_success: "Upload successful ",
      upload_error: "Upload is not allowed here",
      download_folder_error: "Sorry, this folder cannot be downloaded right now",
      download_finished: "Download completed",
      download_error: "Unfortunately, the download was not successful",
      new_folder_created_success: "New folder {0} has been created",
      new_folder_created_error: "The folder could not be created",
      load_contents_successful: "Contents loaded successfully",
      folder_unavailable: "Folder not available",
      login_error: "Login not successful. Please check your login name and password.",
      empty_upload: "No documents selected.",
      email_forbidden: "Login via email is not allowed, please use your login name",
      logout_countdown: "Auto logout",
      soon_logout: "You will be logged out automatically in {0} seconds!",
      pls_reset_time: "Stay logged in",
      // containers / file stuff
      filesizes: ["bytes", "KB", "MB", "GB", "TB"],
    }),

    new MLanguage('Français').create({
      // boutons
      new_folder: "Créer un dossier",
      login_button: "Connexion",
      logout_button: "Déconnexion",
      cancel: "Annuler",
      close: "Fermer",
      create: "Créer",
      save: "Enregistrer",
      share: "Partager",
      share_long: "Partager avec l'établissement",
      remove: "Supprimer",
      delete: "Supprimer",
      download: "Télécharger",
      upload: "Télécharger",
      mobile_scan: "Scan",
      dsgvo_cookie_button: "Compris",
      dsgvo_cookie_text: "Ce site utilise des cookies pour garantir le bon fonctionnement du cloud",
      remove_password: "Supprimer le mot de passe",

      // texte
      noClientForSozi: "Aucun client disponible",
      header_title: "ACCÈS AU CLOUD POUR LES PERSONNES SANS LOGEMENT",
      username_placeholder: "Nom de connexion",
      username_hint: "Nom de connexion (PrénomNom)",
      password_placeholder: "Mot de passe",
      password_hint: "N'oubliez pas de ne jamais révéler votre mot de passe à qui que ce soit - nous ne vous le demanderons jamais non plus!",
      password_reset_button: "Profil",
      password_reset_btn_text: "Change ton mot de passe ici :",
      current_password: "Mot de passe actuel",
      new_password: "Nouveau mot de passe",
      repeat_password: "Répéter le mot de passe",
      repeat_new_password: "Répéter le nouveau mot de passe",
      change_password: "Changer de mot de passe",
      pw_changed: "Le mot de passe a été modifié",
      pw_rules_empty: "Le champ ne doit pas être vide",
      pw_rules_min: "Le mot de passe doit comporter au moins 10 caractères",
      pw_rules_match: "Les mots de passe doivent correspondre",
      pw_rules_compromised: "Le mot de passe a été trouvé parmi les mots de passe les plus fréquemment utilisés",
      dir_rules_maxlength: "Le nom est trop long. Veuillez essayer un nom plus court",
      name: "Nom d'inscription (PrénomNom)",
      name_short: "Nom de connexion (PrénomNom)",
      email_rule: "Veuillez saisir une adresse e-mail valide",
      pw_create: "Définir directement le mot de passe",
      create_account: "Créer un compte",
      edit_account: "Modifier le compte",
      delete_account: "Supprimer le compte",
      remove_from_institution: "Supprimer de l'institution",
      resend_welcome_email: "Renvoyer l'e-mail de bienvenue",
      welcome_email_sent: "Envoi de l'e-mail de bienvenue",
      last_login: "Dernière connexion",
      never_logged_in: "Jamais connecté",
      your_clients: "Vos clients",
      your_files: "Vos documents",
      moving_file: "{0} sera déplacé",
      file_move_here: "Déplacer ici",
      file_moved: "Le fichier a été déplacé",
      move_error: "Erreur lors du déplacement",
      preview: "Aperçu",
      no_preview_available: "Aucun aperçu disponible",
      your_institution: "Votre institution: {0}",
      drop_documents: "Relâchez le bouton de la souris ici pour télécharger le(s) document(s)",
      your_documents: "Vos documents",
      new_folder_text: "Quel doit être le nom du nouveau dossier?",
      privacy_policy: "Protection des données",
      imprint: "Mentions légales",
      manage_wolo_text1: "Client de {0}",
      create_member_help: "Si tu ne crées pas de mot de passe avec le/la client(e), il/elle recevra un e-mail pour créer un mot de passe de son propre chef",
      member_created_success: "Le client(e) {0} a été créé avec succès",
      shared_docs_text: "Partager le dossier <b>'{0}'</b>",
      share_search_placeholder: "Nom de la personne ou de l'institution",
      share_institutions_with_access: "Partage de liens et d'établissements",
      email_placeholder: "Adresse électronique",
      shared_until: "Le partage est limité dans le temps jusqu'à {0}",
      shared_until_short: "Partagé jusqu'à {0}",
      documents_upload: "Télécharger le(s) document(s)",
      choose_files: "Sélectionner le(s) document(s)",
      upload_placeholder: "Choisis ton/tes document(s)",
      copy_link: "Copier le lien",
      sharing_create_link: "Créer un lien de partage",
      sharing_create_email: "Créer un e-mail de partage",
      sharing_give_access: "Partager l'accès",
      sharing_protect_pw_cb: "Protéger avec un mot de passe",
      sharing_protect_limited_date_info: "Le partage est valable jusqu'à: {0}",
      sharing_protect_limited_date_info_undef: "Le partage est illimité",
      sharing_protect_limited_date_text: "Jusqu'à quand le partage doit-il être valable ?",
      sharing_protect_limited_date_remove: "Supprimer la date",
      movesimple: "Déplacer",
      renamesimple: "Renommer",
      rename: "{0} renommer",
      rename_text: "Quel doit être le nouveau nom?",
      delete_check: "Voulez-vous vraiment supprimer <b>{0}</b> ?",
      file: "Fichier",
      files: "Fichiers",
      quota_used: "Tu as consommé {0} de {1} volume de données. ({2}%)",
      quota_used_social: "Mémoire: {0}% de {1} consommé",
      document_password_protected: "Le document est protégé par un mot de passe",
      permissions_change: "Modifier les permissions",
      permissions_file_text: "L'établissement / l'assistant social peut-il modifier ce document ?",
      permissions_folder_text: "L'institution / l'assistant social peut-il modifier et télécharger des documents ?",
      sharing_file_permissions: "Peut modifier ce document",
      sharing_folder_permissions: "Peut télécharger/écraser et modifier des documents",
      no_files_here_client: "Il n'y a pas de données dans ce dossier",
      search: "Recherche dans le dossier actuel",
      no_files_here_client_search: "La recherche n'a donné aucun résultat",
      save_success: "Enregistré avec succès",
      no_email: "Aucune adresse électronique fournie",
      your_email: "Votre adresse e-mail :",

      // admin
      create_wolo: "Créer un(e) nouveau client(e)",
      no_files_got_shared: "Rien n'a été partagé avec toi",
      no_files_here_admin: "Rien n'a été partagé avec toi dans ce dossier",

      // alertes / notifications
      notify_copy_link: "Lien copié !",
      rename_success: "Renommé avec succès ",
      rename_error: "Erreur lors du renommage ",
      function_unavailable: "Cette fonction n'est pas encore disponible",
      end_sharing_success: "Partage interrompu ",
      end_sharing_error: "Erreur lors de l'arrêt du partage ",
      delete_success: "Suppression réussie ",
      delete_error: "Erreur lors de la suppression ",
      unlimited_quota: "infini",
      quota_load_success: "Quota chargé avec succès ",
      quota_load_error: "Erreur lors du chargement du quota ",
      new_share_success: "Cela a fonctionné",
      shared_load_success: "Les documents partagés ont été chargés avec succès ",
      shared_load_error: "Erreur lors du chargement des documents partagés ",
      upload_success: "Téléchargement réussi",
      upload_error: "Le téléchargement n'est pas autorisé ici",
      download_folder_error: "Ce dossier ne peut malheureusement pas être téléchargé pour le moment",
      download_finished: "Téléchargement terminé",
      download_error: "Le téléchargement n'a malheureusement pas réussi",
      new_folder_created_success: "Un nouveau dossier {0} a été créé",
      new_folder_created_error: "Le dossier n'a pas pu être créé",
      load_contents_successful: "Contenu chargé avec succès",
      folder_unavailable: "Dossier non disponible",
      login_error: "Échec de la connexion. Veuillez vérifier votre nom de connexion et votre mot de passe",
      empty_upload: "Aucun document sélectionné",
      email_forbidden: "La connexion par e-mail n'est pas autorisée, veuillez utiliser votre nom de connexion",
      logout_countdown: "Déconnexion automatique",
      soon_logout: "Tu seras automatiquement déconnecté dans {0} secondes !",
      pls_reset_time: "Rester connecté",

      // containers / file stuff
      filesizes: ["Octet", "Ko", "Mo", "Go", "To"],

    }),

    new MLanguage('Türkçe').create({
      // düğmeler
      new_folder: "Klasör oluştur",
      login_button: "Giriş yap",
      logout_button: "Oturumu kapat",
      cancel: "İptal",
      close: "Kapat",
      create: "Oluştur",
      save: "Kaydet",
      share: "Paylaş",
      share_long: "Kurum ile paylaş",
      remove: "Kaldır",
      delete: "Sil",
      download: "İndir",
      upload: "yükle",
      mobile_scan: "tara",
      dsgvo_cookie_button: "Anlaşıldı",
      dsgvo_cookie_text: "Bu web sitesi sorunsuz bir bulut deneyimi sağlamak için çerezler kullanmaktadır",
      remove_password: "Parolayı kaldır.",

      // metin
      noClientForSozi: "Kullanılabilir müşteri yok",
      header_title: "EVSİZ İNSANLAR İÇİN BULUTA ERİŞİM",
      username_placeholder: "Oturum açma adı",
      username_hint: "Oturum açma adı (ad soyad)",
      password_placeholder: "Şifre",
      password_hint: "Şifrenizi kimseye söylememeyi unutmayın - biz de sizden asla istemeyeceğiz!",
      password_reset_button: "Profil",
      password_reset_btn_text: "Şifrenizi buradan değiştirin:",
      current_password: "Geçerli parola",
      new_password: "Yeni şifre",
      repeat_password: "Şifreyi tekrarla",
      repeat_new_password: "Yeni şifreyi tekrarla",
      change_password: "Şifreyi değiştir",
      pw_changed: "Şifre değiştirildi",
      pw_rules_empty: "Alan boş olmamalıdır",
      pw_rules_min: "Parola en az 10 karakterden oluşmalıdır",
      pw_rules_match: "Parolalar eşleşmelidir",
      pw_rules_compromised: "Parola en sık kullanılan parolalar arasında bulundu",
      dir_rules_maxlength: "İsim çok uzun. Lütfen daha kısa bir tane deneyin.",
      name: "Oturum açma adı (ad soyad)",
      name_short: "Oturum Açma Adı (AdSoyad)",
      email_rule: "Lütfen geçerli bir e-posta adresi girin",
      pw_create: "Doğrudan parola belirleyin",
      create_account: "Hesap oluştur",
      edit_account: "Hesabı düzenle",
      delete_account: "Hesabı sil",
      remove_from_institution: "Kurumdan çıkar",
      resend_welcome_email: "Karşılama e-postasını yeniden gönder",
      welcome_email_sent: "Hoş geldiniz e-postası gönderildi",
      last_login: "Son giriş",
      never_logged_in: "Daha önce hiç giriş yapmadım",
      your_clients: "Müşterileriniz",
      your_files: "Belgeleriniz",
      moving_file: "{0} taşınacak",
      file_move_here: "Buraya taşı",
      file_moved: "Dosya taşındı",
      move_error: "Taşınırken hata oluştu",
      preview: "Önizleme",
      no_preview_available: "Önizleme mevcut değil",
      your_institution: "Kurumunuz: {0}",
      drop_documents: "Belge(ler) yüklemek için fare düğmesini burada bırakın",
      your_documents: "Belgeleriniz",
      new_folder_text: "Yeni klasörün adı ne olmalı?",
      privacy_policy: "Veri koruma",
      imprint: "Künye",
      manage_wolo_text1: "{0} Müşterisi",
      create_member_help: "Müşteriyle birlikte bir şifre belirlemezseniz, kendi başına bir şifre oluşturması için bir e-posta alacaktır.",
      member_created_success: "İstemci {0} başarıyla oluşturuldu",
      shared_docs_text: "<b>'{0}'</b> klasörünü paylaş",
      share_search_placeholder: "Kişi veya kurum adı",
      share_institutions_with_access: "Bağlantı ve kurum paylaşımları",
      email_placeholder: "E-posta adresi",
      shared_until: "Paylaşım {0} tarihine kadar zaman sınırlıdır",
      shared_until_short: "{0}'a kadar paylaşıldı",
      documents_upload: "Belge(ler) yükle",
      choose_files: "Belge(ler) seçin",
      upload_placeholder: "Belge(ler)inizi seçin",
      copy_link: "Bağlantıyı kopyala",
      sharing_create_link: "Paylaşım bağlantısı oluştur",
      sharing_create_email: "paylaşım e-postası oluştur",
      sharing_give_access: "Erişimi paylaş",
      sharing_protect_pw_cb: "Parola ile koru",
      sharing_protect_limited_date_info: "Paylaşım şu tarihe kadar geçerlidir: {0}",
      sharing_protect_limited_date_info_undef: "Paylaşım sınırsızdır",
      sharing_protect_limited_date_text: "Paylaşım ne zamana kadar geçerli olmalıdır?",
      sharing_protect_limited_date_remove: "Tarihi kaldır",
      movesimple: "Move",
      renamesimple: "Yeniden Adlandır",
      rename: "{0} yeniden adlandır",
      rename_text: "Yeni isim ne olmalı?",
      delete_check: "<b>{0}</b> öğesini gerçekten silmek istiyor musunuz?",
      file: "dosya",
      files: "dosyalar",
      quota_used: "{1} veri hacminin {0}'ını kullandınız. ({2}%)",
      quota_used_social: "Bellek: {1}'in {0}%'ı kullanıldı.",
      document_password_protected: "Belge parola korumalıdır",
      permissions_change: "İzinleri değiştir",
      permissions_file_text: "Kurumun/sosyal çalışanın bu belgeyi düzenleme izni var mı?",
      permissions_folder_text: "Kurumun / sosyal çalışmacının belgeleri düzenleme ve yükleme izni var mı?",
      sharing_file_permissions: "Bu belgeyi düzenleyebilir",
      sharing_folder_permissions: "Belgeleri yükleyebilir/üzerine yazabilir ve düzenleyebilir",
      no_files_here_client: "Bu klasörde veri yok",
      search: "Geçerli klasörde ara",
      no_files_here_client_search: "Arama hiçbir sonuç döndürmedi",
      save_success: "Başarıyla kaydedildi",
      no_email: "E-posta adresi belirtilmemiş",
      your_email: "E-posta adresiniz:",

      // admin
      create_wolo: "Yeni bir müşteri oluşturun",
      no_files_got_shared: "Sizinle hiçbir şey paylaşılmadı",
      no_files_here_admin: "Bu klasörde sizinle hiçbir şey paylaşılmadı",

      // uyarılar / bildirimler
      notify_copy_link: "Bağlantı kopyalandı!",
      rename_success: "Başarıyla yeniden adlandırıldı ",
      rename_error: "Yeniden adlandırma hatası ",
      function_unavailable: "Bu işlev henüz mevcut değil",
      end_sharing_success: "Paylaşım kesildi ",
      end_sharing_error: "Paylaşım sonlandırılırken hata oluştu ",
      delete_success: "Silme başarılı ",
      delete_error: "Silme işleminde hata oluştu ",
      unlimited_quota: "sonsuz",
      quota_load_success: "Kota başarıyla yüklendi ",
      quota_load_error: "Kota yüklenirken hata oluştu ",
      new_share_success: "Bu işe yaradı",
      shared_load_success: "Paylaşılan belgeler başarıyla yüklendi ",
      shared_load_error: "Paylaşılan belgeler yüklenirken hata oluştu ",
      upload_success: "Yükleme başarılı ",
      upload_error: "Buraya yükleme yapılmasına izin verilmiyor",
      download_folder_error: "Üzgünüz, bu klasör şu anda indirilemiyor",
      download_finished: "İndirme tamamlandı",
      download_error: "Maalesef indirme işlemi başarılı olmadı",
      new_folder_created_success: "Yeni klasör {0} oluşturuldu",
      new_folder_created_error: "Klasör oluşturulamadı",
      load_contents_successful: "İçerik başarıyla yüklendi",
      folder_unavailable: "Klasör mevcut değil",
      login_error: "Giriş başarılı değil. Lütfen giriş adınızı ve şifrenizi kontrol edin.",
      empty_upload: "Seçili belge yok.",
      email_forbidden: "E-posta ile girişe izin verilmiyor, lütfen giriş adınızı kullanın",
      logout_countdown: "Otomatik oturum kapatma",
      soon_logout: "{0} saniye içinde otomatik olarak çıkış yapacaksınız!",
      pls_reset_time: "Oturumunuz açık kalsın",

      // containers / file stuff
      filesizes: ["bytes", "KB", "MB", "GB", "TB"],
    }),
  ]
})