<template>
  <div class="shares-list-item-icons">
    <v-menu
      v-model="menu2"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
    </v-menu>
    <!-- BERECHTIGUNGS ICONS -->

    <div class="shares-list-item-permissions">
      <!-- start zeitfreigabe -->
      <!-- UHR ICON -->
      <v-tooltip bottom class="modal-icon menu-icon shares-list-item-permissions-icon">
        <template v-slot:activator="{ on, attrs }">
          <date-modal
            v-on:update_share="
              (key, value) => {
                update_share(key, value);
              }
            "
            :share_date="share.expiration"
            v-bind="attrs"
            v-on="on"
          ></date-modal>
        </template>
        <span v-html="$ml.get('shared_until').replace('{0}', share.expiration)"></span>
      </v-tooltip>
      <!-- ENDE -->

      <!-- SCHLÜSSEL ICON -->
      <!-- @ROBIN v-if="!share_to_institution" einbauen bitte-->
      <v-tooltip
        bottom
        class="modal-icon menu-icon shares-list-item-permissions-icon"
        v-if="share.share_type != 1"
      >
        <template v-slot:activator="{ on, attrs }">
          <password-modal
            v-on:update_share="
              (key, value) => {
                update_share(key, value);
              }
            "
            :password="share.password"
            v-bind="attrs"
            v-on="on"
          ></password-modal>
        </template>
        <!-- ml: Document is password protected -->
        <span v-html="$ml.get('document_password_protected')"></span>
      </v-tooltip>

      <!-- STIFT ICON -->
      <v-tooltip bottom class="modal-icon menu-icon shares-list-item-permissions-icon">
        <template v-slot:activator="{ on, attrs }">
          <permissions-modal
            v-on:update_share="
              (key, value) => {
                update_share(key, value);
              }
            "
            :editable="share.permissions > 1"
            :share="share"
            v-bind="attrs"
            v-on="on"

          ></permissions-modal>
        </template>
        <!-- ml: Sharing is time limited until {0} -->
        <p v-html="$ml.get('shared_until_short').replace('{0}', share.expiration)"></p>
      </v-tooltip>
    </div>

    <!-- ENTFERNEN ICON -->
    <v-tooltip left class="modal-icon menu-icon shares-list-item-icon-remove">
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          v-on="on"
          class="modal-icon menu-icon shares-list-item-icon-remove-icon"
          @click="delete_share()"
          size="30px"
          color="#d55e00"
          >mdi-minus-circle</v-icon
        >
      </template>
      <span v-html="$ml.get('remove')"></span>
    </v-tooltip>
  </div>
</template>

<script>
import passwordModal from './sharing/password.vue';
import permissionsModal from './sharing/permissions.vue';
import dateModal from './sharing/date.vue';

export default {
  name: 'ShareTooltips',
  components: {
    passwordModal,
    permissionsModal,
    dateModal
  },
  props: {
    share: Object,
    componentKey: Number
  },
  mounted() {},

  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),

      modal: false,

      menu2: false
    };
  },

  methods: {
    conv(decimal) {
      let erg = '000000' + (this.share.permissions >>> 0).toString(2);
      erg = erg.split('').reverse().join('');
      let index = Math.log(decimal) / Math.log(2);
      return erg.charAt(index) == '1';
    },
    delete_share() {
      this.$emit('delete_share', this.share);
    },
    update_share(key, value) {
      if (key === 'permissions') {
        // need to shut of permission
        this.$emit('update_share', this.share.id, key, value + 1);
      } else if (key === 'expireDate') {
        this.$emit('update_share', this.share.id, key, value);
      } else if (key === 'password') {
        this.$emit('update_share', this.share.id, key, value);
      }

      // this.$emit("update_share", this.share.id, key, value)
    }
  }
};
</script>

<style>
.shares-list-item-icons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.shares-list-item-permissions {
  display: flex;
  justify-content: flex-end;
  padding: 8px;
  margin-right: 20px;
}

.shares-list-item-permissions-icon {
  margin-right: 5px;
}

@media only screen and (max-width: 700px) {
  .shares-list-item-permissions {
    display: flex;
    justify-content: flex-end;
    padding: 8px;
    margin-right: 8px;
  }

  .shares-list-item-permissions-icon {
    margin-right: 3px;
  }
}
</style>
